<template>
    <section class="d-flex">
        <div class="ver-column-promociones">
            <div class="row mx-0 align-items-center border-bottom py-2">
                <i class="icon-left text-general f-14 ml-2 cr-pointer" @click="$router.push({name:'admin.promociones.grupos'})" />
                <div class="col-auto f-18 f-400 text-general px-2">
                    <p class="f-600">
                        {{ nombreGrupo }}
                    </p>
                    <div v-if=" infoCarpeta.estado == 0" class="col-auto f-14 f-400 text-gris2 px-1">
                        Grupo inactivo
                    </div>
                </div>
                <div class="btn-action border ml-auto mr-2">
                    <el-popover ref="popoverOptions" placement="bottom" effect="light">
                        <div class="row mx-0 cr-pointer p-2 f-14 item-popover" @click="editarGrupoPromocion()">
                            Editar
                        </div>
                        <div v-if="infoCarpeta.estado == 1" class="row mx-0 cr-pointer p-2 f-14 item-popover" @click="cambiarEstadoCarpeta(0)">
                            inactivar
                        </div>
                        <div v-else class="row mx-0 cr-pointer p-2 f-14 item-popover" @click="cambiarEstadoCarpeta(1)">
                            activar
                        </div>
                        <div class="row mx-0 cr-pointer p-2 f-14 item-popover" @click="modalEliminarCarpeta()">
                            Eliminar
                        </div>
                        <div slot="reference" class="btn-action-outline-small">
                            <i class="icon-dots-vertical f-20" />
                        </div>
                    </el-popover>
                </div>
                <el-tooltip placement="bottom" content="Crear Promoción" effect="light">
                    <div class="btn-red mr-2">
                        <i class="icon-plus f-17" @click="irCrear" />
                    </div>
                </el-tooltip>
            </div>
            <div class="custom-scroll overflow-auto" style="height: calc(100vh - 143px);">
                <div v-if="infoCarpeta.promos == 0" class="row mx-0 justify-center mt-5 pt-4">
                    <div class="col-10 text-center text-general">
                        Aun no se han creado <br /> promociones
                    </div>
                    <div class="col-10 mt-3">
                        <div class="btn-general" @click="$router.push({name: 'admin.promociones.crear'})">
                            Crear promoción
                        </div>
                    </div>
                </div>
                <div v-for="data in infoCarpeta.promos" v-else :key="data.id" class="row mx-0 align-items-center border-bottom pl-2 py-2 cr-pointer" @click="irAPromo(data.id)">
                    <div :class="`${id_promocion==data.id?'br-20 bg-general ml-2':''}` " style="height:60px;width:8px" />
                    <div class="col">
                        <p class="f-15 text-general"> {{ data.nombre }} </p>
                        <div class="row mx-0 mt-2">
                            <div class="col px-0 f-15 text-general">
                                Tipo: {{ textoPromociones(data.tipo) }}
                            </div>
                            <div class="mn-pill bg-gris2 text-white">
                                <i class="icon-cedis f-17" />
                                <span class="mx-1"> {{ data.cantidad_cedis }} </span>
                            </div>
                            <i class="icon-angle-right f-15" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <detallePromocion v-if="showDetalle" @actualizado="getInfoCarpeta" @promoSeleccionada="seleccionarPromo" />
        <modal-confirmar
        ref="modalEliminarCarpeta"
        titulo="Eliminar grupo de promociones "
        mensaje="¿Desea eliminar este grupo?"
        no-aceptar
        adicional="Eliminar"
        @adicional="eliminarCarpetaPromo"
        />
        <modal ref="modalEditarGrupoPromociones" titulo="Editar Grupo de promociones" no-aceptar adicional="Guardar" @adicional="editarCarpetaPromo">
            <div class="row mx-0 justify-center">
                <div class="col-10">
                    <small class="pl-3">
                        Nombre del grupo
                    </small>
                    <el-input v-model="infoCarpeta.nombre" class="w-100" />
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import Carpetas from '~/services/promociones/promociones_carpetas_admin'
export default {
    components:{
        detallePromocion: () => import('./components/detallePromocion.vue'),
    },
    data(){
        return {
            infoCarpeta: {},
            id_carpeta: this.$route.params.idGrupo,
            modalEditar: false,
            id_promocion: null,
            nombreGrupo: '',
            infoPromo: {}
        }
    },
    computed:{
        showDetalle(){
            const name = this.$route.name
            if(name == "admin.promociones.detalle")return true
            return false
        }
    },
    mounted(){
        this.getInfoCarpeta()
    },
    methods: {
        editarGrupoPromocion(){
            this.modalEditar = true
            this.$refs.popoverOptions.doToggle()
            this.$refs.modalEditarGrupoPromociones.toggle();
        },
        modalEliminarCarpeta(){
            this.$refs.popoverOptions.doToggle()
            this.$refs.modalEliminarCarpeta.toggle()
        },
        seleccionarPromo(item){
            this.id_promocion = item
        },
        textoPromociones(state){
            switch (state){
            case 1:
                return '2x1';
            case 2:
                return 'Rebaja valor';
            case 3:
                return 'Descuento fijo';
            case 4:
                return 'Descuento variable';
            case 5:
                return 'Destacado';
            case 6:
                return 'Full categoria';
            }
        },
        async getInfoCarpeta(){
            try {
                const { data } = await Carpetas.getDetalleCarpeta(this.id_carpeta);
                this.infoCarpeta = data.detalleCarpeta;
                this.nombreGrupo = data.detalleCarpeta.nombre
            } catch (error){
                this.error_catch(error)
            }
        },
        async eliminarCarpetaPromo(){
            try {
                const { data } = await Carpetas.eliminarCarpetaPromociones(this.id_carpeta);
                this.$refs.modalEliminarCarpeta.toggle();
                this.notificacion('Éxito',data.mensaje, 'success')
                this.$router.back();

            } catch (error){
                this.error_catch(error)
            }
        },
        cambiarEstadoCarpeta(item){
            this.infoCarpeta.estado = item
            this.editarCarpetaPromo();
        },
        async editarCarpetaPromo(){
            try {
                const payload = {
                    nombreCarpeta: this.infoCarpeta.nombre,
                    estado: this.infoCarpeta.estado
                }
                const { data } = await Carpetas.editarCarpetaPromociones(payload, this.id_carpeta);
                this.notificacion('Éxito', data.mensaje, 'success')
                this.getInfoCarpeta()
                if(this.modalEditar){
                    this.$refs.modalEditarGrupoPromociones.toggle();
                    this.modalEditar = false
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        irCrear(){
            this.$router.push({name:'admin.promociones.crear', params:{idGrupo:this.id_carpeta}})
        },
        irAPromo(item){
            this.$router.push({name:'admin.promociones.detalle',params:{idPromocion: item}}).catch((e) => e)
        }
    }
}
</script>
<style lang="scss" scoped>
.ver-column-promociones{
    width: 380px;
    background-color: #FFFFFF;
    border-radius: 8px 0px 0px 0px;
}
</style>
